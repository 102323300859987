/* eslint-disable */
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Layout from '../shared/components/Layouts/Inner';
import SEO from '../shared/components/SEO';
import Section from '../shared/components/Section';
import Subscribe from '../shared/components/Subscribe';
import ContainerFixed from '../shared/components/Layouts/ContainerFixed';
import Introduction from '../shared/components/Introduction';

import moment from 'moment';

const StyledContainer = styled.article`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  padding: 24px;
  max-width: 720px;

  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  align-items: center;
  margin: 24px auto;
`;

// const BlogThumbnail = styled(Img)``;

const BlogAuthorWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0.75rem 0;

  span {
    font-size: 0.8rem;
    color: ${(props) => props.theme.dark50};
  }
`;

const BlogAuthorThumbnail = styled(Img)`
  border-radius: 100%;
  margin-right: 15px;
`;

export const query = graphql`
  {
    posts: allContentfulPosts(sort: { fields: [date], order: DESC }) {
      nodes {
        title
        slug
        date
        childContentfulPostsContentRichTextNode {
          json
        }
      }
    }
    placeholderImage: file(relativePath: { eq: "blog-placeholder.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    placeholderAuthorImage: file(relativePath: { eq: "blog-placeholder.jpg" }) {
      childImageSharp {
        fixed(width: 30, height: 30) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

function findFirstPara(contentList) {
  var firstPara = contentList.find((item) => {
    if (item.nodeType === 'paragraph') {
      return item;
    }
  });

  return documentToReactComponents(firstPara);
}

const Blog = ({ data }) => (
  <Layout darkMode>
    <SEO title="Blog" />
    <Section heading="Subscribe to our newsletter">
      <Subscribe />
    </Section>

    <ContainerFixed>
      <Introduction title="Blog" hideBtn></Introduction>

      {data.posts.nodes
        ? data.posts.nodes.map((post) => (
            <StyledContainer key={`post-${post.slug}`}>
              {/* <BlogThumbnail fluid={data.placeholderImage.childImageSharp.fluid} /> */}
              <div>
                <h2>
                  <Link to={`/blog/${post.slug}`}>{post.title}</Link>
                </h2>
                <BlogAuthorWrapper>
                  <BlogAuthorThumbnail fixed={data.placeholderAuthorImage.childImageSharp.fixed} />
                  <span>Scott McMullan · {moment(post.date).format('MMMM Do YYYY')}</span>
                </BlogAuthorWrapper>

                {findFirstPara(post.childContentfulPostsContentRichTextNode.json.content)}
              </div>
            </StyledContainer>
          ))
        : ''}
      <br />
    </ContainerFixed>
  </Layout>
);

Blog.propTypes = {
  data: PropTypes.object,
};

Blog.defaultProps = {
  data: {},
};

export default Blog;
