import React from 'react';
import styled from 'styled-components';

const StyledSubscriptionWrapper = styled.div`
  p {
    margin-top: 1rem;
    margin-bottom: 0;
    &.small {
      font-size: 0.8rem;
    }
  }

  #mc_embed_signup_scroll {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  #mc_embed_signup input.email {
    height: 60px;
    padding: 0 1rem;
    border: 0;
  }

  #mc_embed_signup .button {
    height: 60px;
    background: ${(props) => props.theme.primary};
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    border: 0;
    padding: 0 1rem;

    &:hover {
      background-color: #233858;
      cursor: pointer;
    }
  }
`;
export default function Subscribe() {
  return (
    <StyledSubscriptionWrapper>
      <link
        href="//cdn-images.mailchimp.com/embedcode/horizontal-slim-10_7.css"
        rel="stylesheet"
        type="text/css"
      />

      <div id="mc_embed_signup">
        <form
          action="https://maidupnorth.us12.list-manage.com/subscribe/post?u=16dfaa5b019056a41dde4a0ca&amp;id=ba832e1bbd"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className="validate"
          target="_blank"
          noValidate
        >
          <div id="mc_embed_signup_scroll">
            <input
              type="email"
              defaultValue=""
              name="EMAIL"
              className="email"
              id="mce-EMAIL"
              placeholder="Email Address"
              required
            />
            {/* <!-- real people should not fill this in and expect good things
            - do not remove this or risk form bot signups--> */}
            <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
              <input
                type="text"
                name="b_5fcdb247b02ee0f58b399f2b4_e8c4ebb01b"
                tabIndex="-1"
                defaultValue=""
              />
            </div>
            <div className="clear">
              <input
                type="submit"
                value="Subscribe"
                name="subscribe"
                id="mc-embedded-subscribe"
                className="button"
              />
            </div>
          </div>
        </form>
      </div>

      <p className="small">
        No spam, ever. We&apos;ll never share your email address and you can opt out at any time.
      </p>
    </StyledSubscriptionWrapper>
  );
}
